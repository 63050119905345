import { useTranslation } from 'react-i18next';
import { useUser } from '~/modules/useUser';
import LogoutButton from '../LogoutButton';
import AccountLinkItem from './AccountLinkItem';
import { useLanguageParam } from '~/config/i18n';

const GuestLinks = () => {
  const { t } = useTranslation();
  const clientStore = useUser();

  const locale = useLanguageParam();

  return (
    <>
      <div className="list-group">
        <AccountLinkItem
          link={`/${locale}/search?q=Paris%2C+France&place.description=Paris%2C+France&place.highlightDescription=<span+class%3D"-medium">Paris<%2Fspan>%2C+France&place.placeId=ChIJD7fiBh9u5kcRYJSMaMOCCwQ&place.topLat=49.02551879983901&place.topLon=2.560238420124148&place.bottomLat=48.69167167079317&place.bottomLon=2.1338315597725854&place.geoLat=48.856614&place.geoLon=2.3522219&place.originalTopLat=48.90214747577797&place.originalTopLon=2.469850925555473&place.originalBottomLat=48.81556220872687&place.originalBottomLon=2.224219054341255&place.formattedAddress=Paris%2C+France&duration=0&page=1&view=list&firstLoad=true&radius=11&transportLines=`}
          linkText={t('navbar.user.searchOffices.label')}
          iconClass="search"
        />

        <AccountLinkItem
          link={`/${locale}/dashboard/conversations`}
          linkText={t('header.myRequests.label')}
          iconClass="inbox"
        />

        <AccountLinkItem
          link={`/${locale}/favorites`}
          linkText={t('header.favorites.label')}
          iconClass="heart"
        />

        {clientStore?.showDocuments && (
          <AccountLinkItem
            link={`/${locale}/dashboard/documents/contracts`}
            linkText={t('navbar.user.myDocuments.label')}
            iconClass="document"
          />
        )}

        <div className="w-100 fo-border-bottom-md border-1 border-grey-200 my-2"></div>

        <AccountLinkItem
          link={t('navbar.user.guest.getHelp.url')}
          linkText={t('navbar.user.getHelp.label')}
          iconClass="help"
          newTab={true}
        />

        <LogoutButton />
      </div>
    </>
  );
};

export default GuestLinks;
