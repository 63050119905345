import { useTranslation } from 'react-i18next';
import { Button } from '~/components/common/buttons/Button';
import SVGIcon from '~/components/ui/Icons/SVGIcon';
import mixpanelTrack from '~/hooks/useMixPanel';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { Link, useNavigate } from '@remix-run/react';
import { useLanguageParam } from '~/config/i18n';
import Dropdown from '~/components/common/Dropdown';
import { useBootstrapModal } from '~/hooks/useBootstrapModal';

const VisitorMenu = () => {
  const { t } = useTranslation();
  const lang = useLanguageParam();
  const navigate = useNavigate();

  const { showModal } = useBootstrapModal('signInModal');

  const handleOpen = () => {
    const signInModal = document.getElementById('signInModal') as HTMLElement;
    if (signInModal) {
      mixpanelTrack('0.sign_in_intent');
      showModal();
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className="d-none d-md-block me-3">
        <div className="call-us d-flex flex-column">
          <span className="color-grey-500 mb-1">{t('navbar.user.visitor.company.menu.callUs.label')}</span>
          <span className="text-1 -semibold text-nowrap">
            <a
              href={`tel:${t('navbar.user.visitor.company.menu.callUs.phoneNumber')}`}
              onClick={() => {
                mixpanelTrack('0.company_call_us');
              }}
            >
              {t('navbar.user.visitor.company.menu.callUs.phoneNumber')}
            </a>
          </span>
        </div>
      </div>

      <Button
        role="link"
        extraClasses="d-none d-md-block me-2"
        variant="gradientGhostAccent"
        onClick={() => {
          mixpanelTrack('0.list_your_office_nav');
          navigate(`/${lang}/signup/host`);
        }}
      >
        <span className="text-nowrap d-none d-lg-inline"> {t('global.btns.listMyOffice')}</span>
        <span className="text-nowrap d-none d-md-inline d-lg-none"> {t('global.btns.listMyOfficeShort')}</span>
      </Button>

      <div className="d-block d-md-none me-lg-6">
        <LanguageSwitcher variant="primaryGhost" />
      </div>

      <div className="d-none d-md-block me-md-2">
        <Dropdown
          dropdownAlignment="right"
          dropdownAlignmentLg="left"
        >
          {/* Toggle */}
          <Button
            extraClasses="d-none d-md-flex px-2 px-lg-4"
            variant="primaryLight"
            name="Company menu"
            onClick={() => {
              mixpanelTrack('0.search_help');
            }}
            dataAttributes={{ 'data-bs-toggle': 'dropdown' }}
            ariaAttributes={{ 'aria-expanded': 'false', 'aria-label': 'User menu' }}
          >
            <span className="text-nowrap me-2">{t('navbar.user.visitor.company.label')}</span>
          </Button>
          {/* Menu */}
          <div className="list-group pt-4 pb-3 d-flex flex-column align-items-start">
            <Link
              to={t('global.linkPaths.aboutUs')}
              className="cursor-pointer py-3 list-group-item list-group-item-action d-flex align-items-center"
              onClick={() => {
                mixpanelTrack('0.company_about_us');
              }}
            >
              <div className="text-1 -medium text-nowrap flex-fill">
                {t('navbar.user.visitor.company.menu.aboutUs')}
              </div>
              <SVGIcon
                iconType={'long-arrow-right'}
                width={'24'}
                height={'24'}
                fill={'#706D78'}
              />
            </Link>

            <Link
              to={t('global.linkPaths.blog')}
              reloadDocument={true}
              className="cursor-pointer py-3 list-group-item list-group-item-action d-flex align-items-center"
              onClick={() => {
                mixpanelTrack('0.company_blog');
              }}
            >
              <div className="text-1 -medium text-nowrap flex-fill">{t('navbar.user.visitor.company.menu.blog')}</div>
              <SVGIcon
                iconType={'long-arrow-right'}
                width={'24'}
                height={'24'}
                fill={'#706D78'}
              />
            </Link>

            <Link
              to={t('global.linkPaths.contactUs')}
              reloadDocument={true}
              className="cursor-pointer py-3 list-group-item list-group-item-action d-flex align-items-center"
              onClick={() => {
                mixpanelTrack('0.company_contact_us');
              }}
            >
              <>
                <div className="text-1 -medium text-nowrap flex-fill">
                  {t('navbar.user.visitor.company.menu.contactUs')}
                </div>
                <SVGIcon
                  iconType={'long-arrow-right'}
                  width={'24'}
                  height={'24'}
                  fill={'#706D78'}
                />
              </>
            </Link>
          </div>
        </Dropdown>
      </div>

      <Dropdown
        dropdownAlignment="right"
        minWidth="304px"
      >
        {/* Toggle */}
        <Button
          extraClasses="d-none d-md-flex px-2 px-lg-4"
          variant="navPrimaryLightOutline"
          name="User menu"
          onClick={() => {
            mixpanelTrack('0.call_us');
          }}
          dataAttributes={{ 'data-bs-toggle': 'dropdown' }}
          ariaAttributes={{ 'aria-expanded': 'false', 'aria-label': 'User menu' }}
        >
          <span className="d-none d-lg-inline-block text-nowrap me-2">{t('global.btns.signIn')}</span>
          <SVGIcon
            iconType={'user'}
            className="m-0"
          />
        </Button>
        {/* Menu */}
        <div className="list-group pt-4 pb-3 d-flex flex-column align-items-start">
          <button
            className="list-group-item py-3 list-group-item-action d-flex align-items-center"
            onClick={
              () => handleOpen()
              // TODO: google-analytics event, clean up DOM attributes
            }
          >
            <span className="fo-btn-text lh-base -medium text-nowrap">
              {t('navbar.user.visitor.myAccount.dropdown.signIn')}
            </span>
          </button>

          <div className="w-100 text-1 -regular color-grey-500 px-4 py-3 mt-2 fo-border-top-sm border-grey-200">
            {t('navbar.user.visitor.myAccount.dropdown.noAccount')}
          </div>

          <div
            role="link"
            className="cursor-pointer py-3 list-group-item list-group-item-action d-flex align-items-center"
            onClick={() => {
              mixpanelTrack('B.find_an_office');
              navigate(`/${lang}/signup/guest`);
            }}
          >
            <div className="text-1 -medium text-nowrap">{t('navbar.user.visitor.myAccount.dropdown.guestSignUp')}</div>
          </div>

          <div
            role="link"
            className="cursor-pointer py-3 list-group-item list-group-item-action d-flex align-items-center"
            onClick={() => {
              mixpanelTrack('A.publish_an_office');
              navigate(`/${lang}/signup/host`);
            }}
          >
            <div className="text-1 -medium text-nowrap">{t('navbar.user.visitor.myAccount.dropdown.hostSignUp')}</div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default VisitorMenu;
